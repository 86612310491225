html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin-top: 0;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
}

header {
  background-image: none !important;
  box-shadow: none !important;
}

.MuiDialog-container {
  height: 75% !important;
}

.no-top-left-padding {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}